.App-logo {
  height: 40vmin;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.App-link {
  color: #09d3ac;
}
body {
  background-color: #eff0f4;
}
h1 {
  font-family: Lato, sans-serif;
  text-align: left;
}
.brand-0.background {
  background-color: #153375;
}
.card {
  background-color: white;
  box-shadow: 0 0 10px #bdbdbd;
}
.error {
  color: #b00;
  font-size: 12px;
  font-weight: 700;
  text-transform: none;
  font-family: Lato, sans-serif;
}
.margin-right-10 {
  margin: 10px;
}
.margin-right-16 {
  margin-right: 16px;
}
.margin-right-24 {
  margin-right: 24px;
}
.error-wrap {
  color: #bf0000;
  font-size: 12px;
  font-weight: 700;
  text-transform: none;
  font-family: Lato, sans-serif;
}
.error-icon {
  display: inline-flex;
  margin-right: 6px;
  justify-content: center;
  align-items: center;
  box-sizing: content-box;
  width: 12px;
  height: 12px;
  color: #bf0000;
  font-size: 12px;
  font-weight: 500;
  border: 2px solid #bf0000;
  border-radius: 50%;
}
.error-icon svg {
  width: 8px;
  height: 8px;
  fill: #bf0000;
}
.error-icon svg path {
  fill: #bf0000;
}
.clickable {
  cursor: pointer;
}
.nowrap {
  white-space: nowrap;
}
.tab-text {
  text-transform: uppercase;
  color: #1e69d2;
  cursor: pointer;
  white-space: nowrap;
  font-size: 12px;
  position: relative;
  padding-top: 21px;
}
.tab-text.selected * {
  color: #222;
  cursor: default;
  border-bottom: none;
}
.tab-text.selected:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 4px;
  background-color: #1e69d2;
  bottom: -14px;
  left: 0;
}
.tab-text.selected:hover * {
  border-bottom: none;
}
.label-text {
  color: #999;
  text-transform: uppercase;
  display: block;
  padding-bottom: 5px;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 1px;
}
.case-card-divider {
  border: 0;
  clear: both;
  display: block;
  width: calc(100% + 34px);
  background-color: #c9c9c9;
  height: 1px;
  margin-left: -17px;
}
.side-nav-bar {
  display: block;
  color: inherit;
  text-decoration: inherit;
  letter-spacing: 1px;
}
.side-nav-bar:hover {
  background-color: #eff0f4;
}
.side-nav-bar.active {
  color: #1e69d2;
}
.side-nav-bar.active::before {
  content: '';
  height: 42px;
  width: 3px;
  border-radius: 1px;
  background-color: #1e69d2;
  position: absolute;
}
.container.standard-gap > div:not(:first-child) {
  padding-left: 8px;
}
.container.standard-gap > div:not(:last-child) {
  padding-right: 8px;
}
.pagination {
  display: inline-block;
  padding-left: 0;
  border-radius: 4px;
  font-family: Lato, sans-serif;
  font-size: 12px;
  font-weight: 700;
}
.pagination > li {
  display: inline;
}
.pagination > .active > a, .pagination > .active > a:focus, .pagination > .active > a:hover, .pagination > .active > span, .pagination > .active > span:focus, .pagination > .active > span:hover {
  color: #337ab7;
  border-bottom: 2px solid #1e69d2;
  cursor: default;
}
.pagination > .disabled > a, .pagination > .disabled > a:focus, .pagination > .disabled > a:hover, .pagination > .disabled > span, .pagination > .disabled > span:focus, .pagination > .disabled > span:hover {
  color: #777;
  background-color: inherit;
  cursor: not-allowed;
}
.pagination > li > a, .pagination > li > span {
  position: relative;
  float: left;
  padding: 6px 12px;
  line-height: 1.42857143;
  text-decoration: none;
  color: #1e69d2;
  background-color: inherit;
  margin-left: -1px;
}
.pagination-dropdown > div > div {
  top: auto;
  bottom: 100%;
}
.pagination-dropdown > div > div > div > div {
  border: none;
  width: 100%;
  font-size: 14px;
  font-family: Lato, sans-serif;
}
.pagination-dropdown input {
  background: none;
  font-size: 14px;
  font-family: Lato, sans-serif;
  font-weight: 600;
  padding-left: 0px;
  padding-right: 0px;
  width: 100%;
  text-align: center;
}
.pagination-dropdown button {
  padding-left: 0px;
  padding-right: 0px;
  margin-top: -3px;
}
.pagination-dropdown > div > div:nth-child(2) {
  font-size: 12px;
  font-family: Lato, sans-serif;
  width: 80px;
  border: none;
  box-shadow: 0 0 10px #bdbdbd;
}
.alert-box-shadow {
  -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.3);
}
.btn-small-container button {
  padding: 8.6px 32px;
}
.angle-right {
  font-size: 18px;
}
.search-container {
  float: right;
}
.MuiPaper-elevation1 {
  box-shadow: none;
  border-bottom: 1px solid #c9c9c9;
  background-color: transparent;
}
.ribbonhollow .icon-ribbonhollow {
  cursor: pointer;
}
.ribbonhollow {
  cursor: pointer;
}

.center-ascension-loader {
  display: block;
  margin: 0 auto;
  position: absolute;
  top: calc(50% - 40px);
  left: calc(50% - 40px);
}
.blink-logo-color-teal {
  animation: teal-blink 1s ease infinite;
}
@-webkit-keyframes teal-blink {
  0%, 20% {
    fill: #cbcbcb;
  }
  40%, 60%, 80% {
    fill: #00a890;
  }
  100% {
    fill: #cbcbcb;
  }
}
@-moz-keyframes teal-blink {
  0%, 20% {
    fill: #cbcbcb;
  }
  40%, 60%, 80% {
    fill: #00a890;
  }
  100% {
    fill: #cbcbcb;
  }
}
@keyframes teal-blink {
  0%, 20% {
    fill: #cbcbcb;
  }
  40%, 60%, 80% {
    fill: #00a890;
  }
  100% {
    fill: #cbcbcb;
  }
}
.blink-logo-color-blue {
  animation: blue-blink 1s ease infinite;
}
@-webkit-keyframes blue-blink {
  0% {
    fill: #cbcbcb;
  }
  20%, 40%, 60%, 80% {
    fill: #1b4297;
  }
  100% {
    fill: #cbcbcb;
  }
}
@-moz-keyframes blue-blink {
  0% {
    fill: #cbcbcb;
  }
  20%, 40%, 60%, 80% {
    fill: #1b4297;
  }
  100% {
    fill: #cbcbcb;
  }
}
@keyframes blue-blink {
  0% {
    fill: #cbcbcb;
  }
  20%, 40%, 60%, 80% {
    fill: #1b4297;
  }
  100% {
    fill: #cbcbcb;
  }
}
.blink-logo-color-red {
  animation: pink-blink 1s ease infinite;
}
@-webkit-keyframes pink-blink {
  0%, 20%, 40% {
    fill: #cbcbcb;
  }
  60%, 80% {
    fill: #b40f87;
  }
  100% {
    fill: #cbcbcb;
  }
}
@-moz-keyframes pink-blink {
  0%, 20%, 40% {
    fill: #cbcbcb;
  }
  60%, 80% {
    fill: #b40f87;
  }
  100% {
    fill: #cbcbcb;
  }
}
@keyframes pink-blink {
  0%, 20%, 40% {
    fill: #cbcbcb;
  }
  60%, 80% {
    fill: #b40f87;
  }
  100% {
    fill: #cbcbcb;
  }
}
 